import React from 'react';
import Helmet from 'react-helmet';
import { Maybe, SiteSiteMetadata } from '../../types/graphql-types';

import favicon from '../images/favicon.ico';
import socialMediaImage from '../images/social-media-image.png';

interface IProps {
  siteMetadata: Maybe<Pick<SiteSiteMetadata, 'title' | 'description' | 'url'>>;
}

const SiteHelmet = ({ siteMetadata }: IProps) => {
  const getSiteMetaDataProperty = (
    key: keyof Pick<SiteSiteMetadata, 'title' | 'description' | 'url'>
  ) => {
    if (siteMetadata && key in siteMetadata) {
      return siteMetadata[key] || undefined;
    }

    return undefined;
  };

  return (
    <Helmet
      title={getSiteMetaDataProperty('title')}
      link={[
        { href: getSiteMetaDataProperty('url') },
        { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` }
      ]}
    >
      <meta
        name="description"
        content={getSiteMetaDataProperty('description')}
      />
      <meta property="og:title" content={getSiteMetaDataProperty('title')} />
      <meta property="og:url" content={getSiteMetaDataProperty('url')} />
      <meta
        property="og:description"
        content={getSiteMetaDataProperty('description')}
      />
      <meta property="og:image" content={socialMediaImage} />
      <meta property="og:type" content="website" />
    </Helmet>
  );
};

export default SiteHelmet;
